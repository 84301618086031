import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { BText } from "../components/chakra/BText"

function NotFoundPage(props) {
  const { data, location } = props
  const { site } = data || {}
  const { siteMetadata } = site || {}
  const { title, strapiUrl } = siteMetadata || {}

  return (
    <Layout location={location} title={title} strapiUrl={strapiUrl}>
      <Seo title={"Bitlight"} />
      <BText as={"h1"} id={"not-found"}>
        There's nothing here
      </BText>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        strapiUrl
      }
    }
  }
`
